<script>
  export let errors;
  export let buttonText;
</script>

<button
  class="button button-dark button-rounded ml-0 shadow-sm"
  type="submit"
  disabled={errors.length > 0}>{buttonText}</button
>
