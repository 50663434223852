<script>
  export let field;

  field.value = field.value !== null ? field.value : [];
</script>

<div class="form-check">
  {#each field.listvalues as v}
    <span style="display:block">
      <input
        type="radio"
        bind:group={field.value}
        value={v}
        name={field.name}
      /> <span class="form-check-label"> {v}</span>
    </span>
  {/each}
</div>
