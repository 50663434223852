<script>
  import Select from 'svelte-select';
  import FieldCheckbox from './FieldCheckbox.svelte';
  import FieldRadio from './FieldRadio.svelte';
  export let fields;
  export let placeholder;
</script>

{#each fields as field, i}
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <div class="form-group">
    {#if !placeholder || field.mode === 'list'}
      <label class="control-label">{field.text}</label>
    {/if}

    {#if field.fieldtype === 'number'}
      <input
        type="number"
        placeholder={placeholder ? field.text : ''}
        id={field.name}
        bind:value={field.value}
        class="form-control shadow-sm"
        class:invalid={field.invalid}
        required={field.required}
      />
    {:else if field.fieldtype === 'url'}
      <input
        placeholder={placeholder ? field.text : ''}
        type="url"
        id={field.name}
        bind:value={field.value}
        class="form-control shadow-sm"
        class:invalid={field.invalid}
        required={field.required}
      />
    {:else if field.fieldtype === 'text'}
      <input
        placeholder={placeholder ? field.text : ''}
        type="text"
        id={field.name}
        bind:value={field.value}
        class="form-control shadow-sm"
        class:invalid={field.invalid}
        required={field.required}
      />
    {:else if field.fieldtype === 'email'}
      <input
        placeholder={placeholder ? field.text : ''}
        type="email"
        id={field.name}
        bind:value={field.value}
        class="form-control shadow-sm"
        class:invalid={field.invalid}
        required={field.required}
      />
    {:else if field.fieldtype === 'dropdown'}
      <Select
        class="form-control shadow-sm"
        items={field.listvalues}
        id={field.name}
        bind:value={field.value}
        required={field.required}
      />
    {:else if field.fieldtype === 'checkbox'}
      <FieldCheckbox bind:field />
    {:else if field.fieldtype === 'radio'}
      <FieldRadio bind:field />
    {:else if field.fieldtype === 'textarea'}
      <textarea
        class="form-control shadow-sm"
        class:invalid={field.invalid}
        bind:value={field.value}
        id={field.name}
        placeholder={placeholder ? field.text : ''}
      />
    {/if}
    {#if field.invalid}
      <div class="error alert">{field.validationMessage}</div>
    {/if}
  </div>
{/each}
