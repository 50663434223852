<script>
  export let field;

  field.value = field.value !== null ? field.value : [];
</script>

<!-- svelte-ignore a11y-label-has-associated-control -->

<div class="form-check">
  {#each field.listvalues as v}
    <span class="form-check-label" style="display:block">
      <input
        type="checkbox"
        bind:group={field.value}
        value={v}
        name={field.name}
        class="form-check-input"
      /><span class="form-check-label">{v} </span>
    </span>
  {/each}
</div>
