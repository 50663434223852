<script>
  console.log('loading form');

  import RenderFields from './lib/RenderFields.svelte';
  import SubmitButton from './lib/SubmitButton.svelte';

  export let config;
  export let pageId;
  export let buttonText;
  export let placeholder;

  let errors = [];
  $: isValid = errors.length === 0 && fields.submitted;
  $: fields = Object.assign(config.fields);
  $: showState = 'form';
  $: message = null;

  function restart() {
    errors = [];
    fields = Object.assign(config.fields);
    showState = 'form';
    message = null;
  }

  function validateFields() {
    errors = [];
    if (!fields.submitted) return;
    for (let field of fields) {
      if (
        field.required &&
        (field.value === null || field.value.length === 0)
      ) {
        field.invalid = true;
        errors.push(`${field.text} skal udfyldes`);
        field.validationMessage = `${field.text} skal udfyldes`;
      } else {
        field.invalid = false;
      }
    }
    return errors.length === 0;
  }

  $: {
    isValid = fields && validateFields();
  }

  async function handleSubmit() {
    debugger;
    console.log('submitting form');
    fields.submitted = true;
    isValid = validateFields();
    if (!isValid) return;

    // konverter checkbox multi fra array til string
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i];

      if (Array.isArray(field.value)) {
        field.value = field.value.toString();
      }
    }
    const body = {
      fields,
      key: config.key,
      pageid: `${pageId}`,
    };

    const headers = { 'Content-Type': 'application/json' };
    const res = await fetch('/Umbraco/Api/umadeform/post', {
      //https://httpbin.org/post'
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...body,
      }),
    });
    debugger;
    console.log('res', res);
    if (res.ok) {
      const data = await res.json();
      showState = 'validresponse';
      let response = data;
      message = response?.data?.message;
      if (message) {
        for (let i = 0; i < fields.length; i++) {
          let field = fields[i];
          if (Array.isArray(field.value)) {
            field.value = field.value.toString();
          }
          message = message.replace(`{${i}}`, field.value);
        }
      } else {
        message = 'Tak for din henvendelse';
      }
    } else {
      showState = 'error';
    }
  }
</script>

{#if showState === 'form'}
  <form
    on:submit|preventDefault={handleSubmit}
    class="form-horizontal"
    role="form"
  >
    <RenderFields bind:fields {placeholder} />
    <SubmitButton {buttonText} {errors} />
  </form>
{:else if showState === 'validresponse'}
  <div on:click={restart}>{@html message}</div>
{:else}
  <div>Der er sket en fejl</div>
{/if}
